// SuccessScreen.js
import React from 'react';
import './Success.css';
import { useState } from 'react';
import { FaCheckCircle } from 'react-icons/fa';
import { useSpring, animated } from 'react-spring';

const Success = ({ onClose }) => {


  const animationProps = useSpring({
    opacity: 1,
    transform: 'translateY(0)',
    from: { opacity: 0, transform: 'translateY(-20px)' },
    // onRest: onClose,
  });
  // const handleClose = () => {
  //   window.location.reload(); // Reload the page when close button is clicked
  // };

  return (
    <div className="success-screen-overlay">
      <animated.div style={animationProps} className="success-screen-content">
        <FaCheckCircle className="success-icon" />
        <p>Your order has been successfully placed!</p>
        <button onClick={onClose}>Close</button>
      </animated.div>
    </div>
  );
};

export default Success;
