import React from 'react'
import GenFoot from './GenFoot'
import { DatePicker, Space, theme } from 'antd';
import dayjs from 'dayjs';
import { TimePicker } from 'antd';
const format = 'HH:mm';

const Wedding = () => {

    const { token } = theme.useToken();
    const style = {
    //   border: `1px solid ${token.colorPrimary}`,
        color:"white",
      borderRadius: '10%',
    //   with:"100%",
    };
    const cellRender = React.useCallback((current, info) => {
      if (info.type !== 'date') {
        return info.originNode;
      }
      if (typeof current === 'number') {
        return <div className="ant-picker-cell-inner">{current}</div>;
      }
      return (
        <div className="ant-picker-cell-inner" style={current.date() === 1 ? style : {}}>
          {current.date()}
        </div>
      );
    }, []);

  return (
    <div className=''>
        <div className=' bg-[url("https://lirp.cdn-website.com/5b4e14d8/dms3rep/multi/opt/Services+Hero-5bb8eccc-2880w.jpg")] lg:h-screen items-center pt-32 md:pt-10 lg:pt-2 pb-10 bg-center lg:p-24'>
            <div className='items-center lg:mt-[20%] text-center'>
            <h2 className='text-gray-400 mt-14 font-bold pl-5 lg:pl-0'>WE OFFER</h2>
            <h1 className='text-gray-200 lg:text-7xl text-4xl pl-5 lg:pl-0 font-bold mt-5'>Wedding Transportation</h1>
            <h2 className='text-gray-400 mt-10 text-2xl pl-5 lg:pl-0'>Let us help make your special day as great as possible. When  <br className='hidden lg:inline'/>it comes to transportation, trust us to take care of everything..</h2>
            </div>
        {/* <div className=' mt-32'>
            <img className='h-[100%] w-[100%]' src="https://lirp.cdn-website.com/fc25127f/dms3rep/multi/opt/City+image-2880w.jpg" alt="" />
        </div> */}
        </div>
        <div className='bg-black px-10 pb-20'>
            <h2 className=' text-center pt-10 text-gray-200'>Fill out the form below and we will get back to you within 24 hours</h2>
            <div className='lg:px-32 mt-14 lg:ml-32'>
                <h2 className='font-bold text-3xl text-gray-700'>Request An Airport Transfer</h2>
                <div className='mt-20'>
                    <div className=' mt-5 p-y5'>
                    <label className='text-gray-200' htmlFor="">Name:</label> <br />
                    <input className='bg-black h-10 w-[100%] border-b-2  focus:border-b-o border-b-gray-200' type="text" name="" id="" />
                    </div>

                    <div className='mt-5 py-5'>
                    <label className='text-gray-200 mt-10' htmlFor="">Email:</label> <br />
                    <input className='bg-black w-[100%] h-10 border-b-2  focus:border-b-o border-b-gray-200' type="email" name="" id="" />
                    </div>
                    <div className='mt-5 py-5'>
                    <label className='text-gray-200 mt-10' htmlFor="">Phone:</label> <br />
                    <input className='bg-black w-[100%] h-10 border-b-2  focus:border-b-o border-b-gray-200' type="number" name="" id="" />
                    </div>

                    <div className='mt-5 w-[100%] gap-5 flex py-5'>
                        <div className='w-[50%]'>
                            <label className='text-gray-200 mt-10' htmlFor="">Date</label> <br />
                            <div className='bg-black w-[100%] h-10 border-b-2  focus:border-b-o border-b-gray-200'>
                                <Space size={12} style={{width:200}} direction="vertical">
                                <DatePicker cellRender={cellRender} style={{backgroundColor:"black", border:0, width:200, color:"white"}} />
                                {/* <DatePicker.RangePicker cellRender={cellRender} /> */}
                                </Space>
                              </div>
                        </div>
                        <div className='w-[50%]'>
                            <label className='text-gray-200 mt-10' htmlFor="">Time</label> <br />
                            <div className='bg-black w-[100%] h-10 border-b-2  focus:border-b-o border-b-gray-200' type="text" name="" id="" >
                            <TimePicker defaultValue={dayjs('12:08', format)} format={format} />;
                            </div>
                        </div>
                    </div>

                    <div className='mt-5 py-5'>
                    <label className='text-gray-200 mt-10' htmlFor="">Airport</label> <br />
                    <input className='bg-black w-[100%] h-10 border-b-2  focus:border-b-o border-b-gray-200' type="text" name="" id="" />
                    </div>
                    <div className='mt-5 py-5'>
                    <label className='text-gray-200 mt-10' htmlFor="">Pick - Up / Drop Off Location</label> <br />
                    <input className='bg-black w-[100%] h-10 border-b-2  focus:border-b-o border-b-gray-200' type="text" name="" id="" />
                    </div>
                    <div className='mt-5 py-5'>
                    <label className='text-gray-200 mt-10' htmlFor="">Anything We Need to Know?</label> <br />
                    <input placeholder='How many Passengers & Bags? Curbside or Meet Greet?' className='bg-black font-bold w-[100%] h-10 border-b-2  focus:border-b-o border-b-gray-200' type="text" name="" id="" />
                    </div>
                </div>
                    <button className='bg-green-500 hover:scale-110 self-end duration-500 font-bold p-4 rounded-full text-white l       g:mt-20 w-40 mt-10 shadow-md'>BOOK A RIDE</button>
            </div>
        </div>
        <GenFoot  />
    </div>
  )
}

export default Wedding