import React from 'react'
import GenFoot from './GenFoot'
import { Link } from 'react-router-dom'

const Packages = () => {
  return (
    <div>
        <div className='bg-[url("https://lirp.cdn-website.com/fc25127f/dms3rep/multi/opt/Services+Hero-2880w.jpg")] lg:h-screen items-center pt-32 md:pt-10 lg:pt-2 pb-10 bg-center bg-no-repeat bg-cover lg:p-24'>
        <div className='items-center lg:mt-[20%] text-center'>
            <h2 className='text-gray-400 mt-1 lg:mt-14 font-bold pl-5 lg:pl-0'>OUR PACKAGES</h2>
            <h1 className='text-gray-100 lg:text-7xl text-4xl pl-5 lg:pl-0 font-bold mt-5'>Some packages we offer</h1>
            <h2 className='text-gray-300 mt-14 lg:text-2xl pl-5 lg:pl-0'>We offer the following packages, but any of them can be catered to your needs</h2>
         </div>
        </div>

        <div className='bg-black lg:-mt-28 py-20 lg:px-32'>

            <div className='bg-slate-900 rounded-lg justify-evenly lg:flex p-14'>
                <div>
                    <h2 className='text-gray-100 lg:text-5xl py-5 text-4xl pl-5 lg:pl-0 font-bold mt-5'>Package 1</h2>
                    <p className='text-gray-300 py-4 pl-5 lg:pl-0'>Here is a description of the package we offer.</p>
                    <Link to={"/Booking"}>
                        <button className='bg-green-500 shadow-lg p-2 w-52 text-white rounded-full font-bold h-16' >Book Now </button>
                        </Link>
                </div>
                <div className=''>
                    <h2 className='text-gray-400 lg:mt- mt-20  text-xl font-bold pl-5 lg:pl-0'>Includes:</h2>
                        <div className='ml-5'>
                            <li className='text-gray-200 font-bold pl-5 lg:pl-0'>4 Hour rental on Party Bus</li>
                            <li className='text-gray-200 font-bold pl-5 lg:pl-0'>Balloons</li>
                            <li className='text-gray-200 font-bold pl-5 lg:pl-0'>Sweet 16 decorations</li>
                            <li className='text-gray-200 font-bold pl-5 lg:pl-0'>Mini Cup Cake Bites</li>
                            <li className='text-gray-200 font-bold pl-5 lg:pl-0'>Soft Drink</li>
                            <li className='text-gray-200 font-bold pl-5 lg:pl-0'>Napkins</li>
                            <li className='text-gray-200 font-bold pl-5 lg:pl-0'>Cups</li>
                            <li className='text-gray-200 font-bold pl-5 lg:pl-0'>Ice</li>
                        </div>
                </div>
            </div>

            <div className='bg-slate-900 rounded-lg mt-14 justify-evenly lg:flex p-14'>
                <div>
                    <h2 className='text-gray-100 lg:text-5xl py-5 text-4xl pl-5 lg:pl-0 font-bold mt-5'>Package 2</h2>
                    <p className='text-gray-300 py-4 pl-5 lg:pl-0'>Here is a description of the package we offer.</p>
                    <Link to={"/Booking"}>
                         <button className='bg-green-500 shadow-lg p-2 w-52 text-white rounded-full font-bold h-16' >Book Now </button>
                    </Link>
                </div>
                <div>
                    <h2 className='text-gray-400 lg:mt- mt-20 text-xl font-bold pl-5 lg:pl-0'>Includes:</h2>
                        <div className='ml-5'>
                            <li className='text-gray-200 font-bold pl-5 lg:pl-0'>4 Hour rental on Party Bus</li>
                            <li className='text-gray-200 font-bold pl-5 lg:pl-0'>Balloons</li>
                            <li className='text-gray-200 font-bold pl-5 lg:pl-0'>Sweet 16 decorations</li>
                            <li className='text-gray-200 font-bold pl-5 lg:pl-0'>Mini Cup Cake Bites</li>
                            <li className='text-gray-200 font-bold pl-5 lg:pl-0'>Soft Drink</li>
                            <li className='text-gray-200 font-bold pl-5 lg:pl-0'>Napkins</li>
                            <li className='text-gray-200 font-bold pl-5 lg:pl-0'>Cups</li>
                            <li className='text-gray-200 font-bold pl-5 lg:pl-0'>Ice</li>
                        </div>
                </div>
            </div>


            <div className='bg-slate-900 rounded-lg mt-14 justify-evenly lg:flex p-14'>
                <div>
                    <h2 className='text-gray-100 lg:text-5xl py-5 text-4xl pl-5 lg:pl-0 font-bold mt-5'>Package 3</h2>
                    <p className='text-gray-300 py-4 pl-5 lg:pl-0'>Here is a description of the package we offer.</p>

                        <Link to={"/Booking"}>
                            <button className='bg-green-500 shadow-lg p-2 w-52 text-white rounded-full font-bold h-16' >Book Now </button>
                        </Link>
                </div>
                <div>
                    <h2 className='text-gray-400 text-xl lg:mt- mt-20 font-bold pl-5 lg:pl-0'>Includes:</h2>
                        <div className='ml-5'>
                            <li className='text-gray-200 font-bold pl-5 lg:pl-0'>4 Hour rental on Party Bus</li>
                            <li className='text-gray-200 font-bold pl-5 lg:pl-0'>Balloons</li>
                            <li className='text-gray-200 font-bold pl-5 lg:pl-0'>Sweet 16 decorations</li>
                            <li className='text-gray-200 font-bold pl-5 lg:pl-0'>Mini Cup Cake Bites</li>
                            <li className='text-gray-200 font-bold pl-5 lg:pl-0'>Soft Drink</li>
                            <li className='text-gray-200 font-bold pl-5 lg:pl-0'>Napkins</li>
                            <li className='text-gray-200 font-bold pl-5 lg:pl-0'>Cups</li>
                            <li className='text-gray-200 font-bold pl-5 lg:pl-0'>Ice</li>
                        </div>
                </div>
            </div>


        </div>

        <GenFoot  />
    </div>
  )
}

export default Packages
