import React, { useEffect, useState } from 'react';
import { GoogleMap } from '@react-google-maps/api';

const containerStyle = {
  width: '100%',
  height: '400px',
};

function Review({ routeDetails }) {
  const { origin, destination, route } = routeDetails;
  const [map, setMap] = useState(null);
  const center = {
    lat: (origin.location.lat + destination.location.lat) / 2,
    lng: (origin.location.lng + destination.location.lng) / 2,
  };

  useEffect(() => {
    if (map && route) {
      // Fit the map to the bounds of the route
      const bounds = new window.google.maps.LatLngBounds();
      route.overview_path.forEach((path) => bounds.extend(path));
      map.fitBounds(bounds);

      // Add Markers
      new window.google.maps.Marker({
        position: origin.location,
        map: map,
        label: 'Pickup',
        icon: {
          url: 'https://maps.google.com/mapfiles/ms/icons/yellow-dot.png', // Yellow marker icon URL
          labelOrigin: new window.google.maps.Point(22, 0) // Adjust the label position if necessary
        }
      });

      new window.google.maps.Marker({
        position: destination.location,
        map: map,
        label: 'Dropoff',
        icon: {
          url: 'https://maps.google.com/mapfiles/ms/icons/green-dot.png', // Yellow marker icon URL
          labelDestination: new window.google.maps.Point(22, 0) // Adjust the label position if necessary
        }
      });

      // Add Polyline
      new window.google.maps.Polyline({
        path: route.overview_path,
        map: map,
        strokeColor: 'blue',
        strokeOpacity: 1,
        strokeWeight: 5,
      });

      // Add DirectionsRenderer
      new window.google.maps.DirectionsRenderer({
        map: map,
        directions: { routes: [route] },
      });
    }
  }, [map, route, origin, destination]);

  return (
    <GoogleMap
      mapContainerStyle={containerStyle}
      mapTypeId="terrain"
      center={center}
      zoom={5}
      onLoad={(map) => setMap(map)}
    />
  );
}

export default Review;
