import styled from "styled-components";
import { DownOutlined, SmileOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import car from "./Img/luxery suv.jpg"
import car1 from "./Img/land cruise.jpg"
import car2 from "./Img/lemogeep suv.jpg"
import car3 from "./Img/lemo large suv.jpg"
import car4 from "./Img/suv_large.jpg"
import car5 from "./Img/long suv.jpg"
import car6 from "./Img/party.jpg"
import car7 from "./Img/executive.jpg"
import car8 from "./Img/exceut.jpg"
import { MdCorporateFare, MdGroup, MdLocalAirport } from "react-icons/md";
import { FaBriefcase } from "react-icons/fa6";

const CustomStyles = {
    control: (provided, state) => ({
      ...provided,
      height: '60px', // Set the desired height
      border: '1px solid #ccc',
      borderRadius: '5px',
      boxShadow: state.isFocused ? '0 0 0 2px #0366d6' : null,
      '&:hover': {
        borderColor: '#0366d6',
      },
    }),
    indicatorSeparator: () => ({ display: 'none' }), // Hide the indicator separator
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? '#d3eafd' : null,
      '&:hover': {
        backgroundColor: '#d3eafd',
      },
    }),
    placeholder: (provided) => ({
      ...provided,
      position: '',
      top: '-10px', // Adjust this value to move the placeholder up
      left: '10px', // Adjust this value to control the left position
      fontSize: '14px', // Adjust font size as needed
      color: '#888', // Adjust color as needed
      pointerEvents: 'none', // Ensure the placeholder does not interfere with interaction
    }),
  };

  const Options = [
    { value: '0.5', label: '0.5Hour' },
    { value: '1', label: '1Hour' },
    { value: '1.5', label: '1.5Hours' },
    { value: '2', label: '2Hours' },
    { value: '2.5', label: '2.5Hours' },
    { value: '3', label: '3Hours' },
    { value: '3.5', label: '3.5Hours' },
    { value: '4', label: '4Hours' },
    { value: '4.5', label: '4.5Hours' },
    { value: '5', label: '5Hours' },,
    { value: '5.5', label: '5.5Hours' },
    { value: '6', label: '6Hours' },
    { value: '6.5', label: '6.5Hours' },
    { value: '7', label: '7Hours' },
    { value: '7.5', label: '7.5Hours' },
    { value: '8', label: '8Hours' },
  ];

  const Type = [
    { value: 'Wedding', label: 'Wedding' },
    { value: 'Birthday', label: 'Birthday' },
    { value: 'Airport Drop Off', label: 'Airport Drop Off' },
    { value: 'Airport Pick Up', label: 'Airport Pick Up' },
    { value: 'Airport', label: 'Airport' },
    { value: '21st Birthday', label: '21st Birthday' },
    { value: 'Business Trip', label: 'Business Trip' },
    { value: 'Corporate', label: 'Corporate' },
    { value: 'Brew Tour', label: 'Brew Tour' },
    { value: 'Bar', label: 'Bar' },
    { value: 'Holiday', label: 'Holiday' },
    { value: 'Anniversary', label: 'Anniversary' },
    { value: 'Kids Birthday', label: 'Kids Birthday' },
    { value: 'Family Reunion', label: 'Family Reunion' },
    { value: 'Funeral', label: 'Funeral' },
    { value: 'Corporate Event', label: 'Corporate Event' },
    { value: 'Concert', label: 'Concert' },
    { value: 'Day Tour', label: 'Day Tour' },
    { value: 'Night Out', label: 'Night Out' },
    { value: 'Bride/Groom', label: 'Bride/Groom' },
    { value: 'Bridal Party', label: 'Bridal Party' },
    { value: 'Wine Tour', label: 'Wine Tour' },
    { value: 'Baseball', label: 'Baseball' },
    { value: 'Football', label: 'Football' },
    { value: 'Basketball', label: 'Basketball' },
    { value: 'Golf', label: 'Golf' },
    { value: 'Hockey', label: 'Hockey' },
    { value: 'Sporting Event', label: 'Sporting Event' },
    { value: 'Bachelor/Bachelorette', label: 'Bachelor/Bachelorette' },
    { value: 'Special Occasion', label: 'Special Occasion' },
    { value: 'School Fundraiser', label: 'School Fundraiser' },
    { value: 'Medical ', label: 'Medical' },
    { value: 'Point-to-Point ', label: 'Point-to-Point' },
    { value: 'Seaport ', label: 'Seaport' },
    { value: 'Field Trip ', label: 'Field Trip' },
    { value: 'School ', label: 'School' },
    { value: 'Graduation ', label: 'Graduation' },
    { value: 'Sweet 16 ', label: 'Sweet 16' },
    { value: 'Quinceanera ', label: 'Quinceanera' },
    { value: 'Train Station ', label: 'Train Station' },
    { value: 'Bar/Bat Mitzvah ', label: 'Bar/Bat Mitzvah' },
    { value: 'Leisure', label: 'Leisure' },
    { value: 'Prom/Homecoming', label: 'Prom/Homecoming' },
  ];

  const CustomStyle = {
    input: {
      borderRadius: '5px',
      border: '1px solid #ccc',
      padding: '8px',
      fontSize: '14px',
    },
  };


  
const theme = {
  blue: {
    default: "#4d4dff",
    // default: "#3f51b5",
    hover: "#0000ff",
    // hover: "#283593",
  },
  pink: {
    default: "#e91e63",
    hover: "#ad1457",
  },
};

const Button = styled.button`
  background-color: ${(props) => theme[props.theme].default};
  height:50px;
  width:150px;
  color: white;
  padding: 5px 15px;
  border-radius: 0px;
  outline: 0;
  border: 0; 
  text-transform: uppercase;
  margin: 20px 0px;
  cursor: pointer;
  box-shadow: 0px 2px 2px lightgray;
  transition: ease background-color 250ms;
  &:hover {
    background-color: ${(props) => theme[props.theme].hover};
  }
  &:disabled {
    cursor: default;
    opacity: 0.7;
  }
  @media (max-width: 768px) {
    /* Styles for screens with a width of 768 pixels or less (typically phone screens) */
    width: 32%; /* Set the width to 100% for responsiveness */
    margin: 10px 0; /* Adjust margin for smaller screens */
    
  }
`;

Button.defaultProps = {
  theme: "blue",
};

const ButtonToggle = styled(Button)`
  opacity: 0.7;
  ${({ active }) =>
    active &&
    `
    opacity: 1; 
  `}
`;

const types = ["Hourly", "One Way", "Round Trip"];
const Types = [" Address", "Airport",];

const Cars = [
  {
    name:"luxury sedan O11",
    description:"Limousine",
    policy:"No alcohol, food, pets, or smoking allowed",
    image:car,
    prize: "110",
    capacity:"3",
    ac: "yes",
    media: "yes",
    id:1,
  },


  {
    name:"SUV",
    description:"Limousine",
    policy:"No alcohol, food, pets, or smoking allowed",
    image:car1,
    prize: "220",
    capacity:"6",
    ac: "no",
    media: "no",
    id:2,
  },

  {
    name:"premium SUV 012",
    description:"Limousine",
    policy:"No alcohol, food, pets, or smoking allowed",
    image:car2,
    prize: "500",
    capacity:"6",
    ac: "no",
    media: "no",
    id:3,
  },

  {
    name:"Mercedes Sprinter van",
    description:"Party Van",
    policy:"No alcohol, food, pets, or smoking allowed",
    image:car3,
    prize: "800",
    capacity:"8",
    ac: "no",
    media: "no",
    id:4,
  },

  {
    name:"Lincoln Town Car",
    description:"Limousine",
    policy:"No alcohol, food, pets, or smoking allowed",
    image:car4,
    prize: "500",
    capacity:"8",
    ac: "no",
    media: "no",
    id:5,
  },

  {
    name:"H2 Hummer limousine",
    description:"Limousine",
    policy:"No alcohol, food, pets, or smoking allowed",
    image:car5,
    prize: "1100",
    capacity:"16",
    ac: "no",
    media: "no",
    id:6,
  },

  {
    name:"Party Bus",
    description:"Party Bus",
    policy:" Alcohol Friendly Food Allowed, No pets, or smoking allowed",
    image:car6,
    prize: "700",
    capacity:"24",
    ac: "no",
    media: "no",
    id:7,
  },

  {
    name:"Executive Bus",
    description:"Mini Coach",
    policy:"No alcohol, food, pets, or smoking allowed",
    image:car7,
    prize: "800",
    capacity:"26",
    ac: "no",
    media: "no",
    id:8,
  },

  {
    name:"Shuttle Bus",
    description:"Mini Coach",
    policy:"Alcohol Friendly Food Allowed, Pets Allowed, No smoking allowed",
    image:car8,
    prize: "700",
    capacity:"27",
    ac: "no",
    media: "no",
    id:9,
  },
]

const items = [
  {
    key: '1',
    label: (
      <Link  to={"/Aurtrans"} className="block px-4 py-5 hover:bg-gray-100 p-1 text-gray-500">Airport Transfer</Link>
    ),
    icon: <MdLocalAirport />,
  },
  {
    key: '2',
    label: (
      <Link to={'/Wedding'} className="block px-4 py-5 hover:bg-gray-100 p-1 text-gray-500">Weddings</Link>
    ),
    icon: <MdGroup />,
    // disabled: true,
  },
  {
    key: '3',
    label: (
      <Link to={"/Coperate"} className="block px-4 py-5 hover:bg-gray-100 p-1 text-gray-500">Corporate</Link>
    ),
    icon: <MdCorporateFare />,
  },
  {
    key: '4',
    label: (
      <Link to={"Parties"} className="block px-4 py-5 hover:bg-gray-100 p-1 text-gray-500">Parties</Link>
    ),
    icon: <MdGroup />,
  },
  {
    key: '5',
    label: (
      <Link to={"/Packages"} className="block px-4 py-5 hover:bg-gray-100 p-1 text-gray-500">Package</Link>
    ),
    icon: <FaBriefcase />,
  },
  {
    key: '6',
    danger: true,
    label: 'close',
  },
];

  export  { Options, CustomStyles, Type, CustomStyle, types, ButtonToggle, Types, Cars, items }