// CarTypes.js
import React, { useState, useEffect } from "react";
import { Cars } from "./data";
import {
  getFirestore,
  collection,
  addDoc,
  onSnapshot,
  query, where,
  doc,
  collectionGroup,
} from "firebase/firestore";
import { firebase } from "../../firebaseConfig";
import { db } from "../../firebaseConfig";
import Success from "./Success";
import Review from "./Review";
import { FaLocationPin } from "react-icons/fa6";
import { FaQuestionCircle } from "react-icons/fa";
// import useHistory from 'react-router-dom';
import {
  FaClock,
  FaCalendarAlt,
  FaUsers,
  FaCar,
  FaMapMarkerAlt,
} from "react-icons/fa";
function CarTypes({
  data,
  onSave,
  setShowSuccessScreen,
  setSavedData,
  routeDetails,
}) {
  const [selectedCar, setSelectedCar] = useState(null);
  const [showReview, setShowReview] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [TPrice, setTPrice] = useState(0);
  const [vehicles, setVehicles] = useState([]);
  // const history = useHistory();

  const handleCarSelection = (vehicle, totalCost) => {
    setSelectedCar(vehicle);
    setTPrice(totalCost); // Update the total price with selected car's price
    setShowReview(true);
    window.scrollTo(0, 0);
  };

  const handleSave = async () => {
    if (selectedCar) {
      try {
        const totalCost =
          parseInt(data.totalCost) + parseInt(selectedCar.prize);

        const docRef = await firebase
          .firestore()
          .collection("trips")
          .add({
            ...data,
            car: {
              name: selectedCar.vehicleName,
              image: selectedCar.images[0],
              prize: selectedCar.prize,
              capacity: selectedCar.capacity,
              // Add more car details as needed
            },
          });

        console.log("Document written with ID: ", docRef.id);

        // Optionally, you can call the onSave callback to notify the parent component
        if (onSave) {
          onSave();
        }

        setSavedData({ ...data, car: selectedCar }); // Set saved data
        setShowSuccess(true);
        // alert("well done your order was successful 👍👍")
      } catch (error) {
        console.error("Error adding document: ", error);
      }
    } else {
      console.error("No car selected.");
    }
  };

  const handleConfirm = () => {
    handleSave();
  };
  const handleGoBack = () => {
    setShowReview(false);
    window.scrollTo(0, 0);
  };

  const handleCloseSuccess = () => {
    // Close the success screen
    setShowSuccess(false);
    window.location.reload();
  };

  useEffect(() => {
    // Define the specific user ID
    const userId = "9BQuQJe1oigQHO6y2cLUGjFGa1s1";
    
    // Reference to the subcollection under the specific user document
    const vehiclesRef = collection(db, "users", userId, "vehicles");
  
    // Set up the query and snapshot listener
    const unsubscribe = onSnapshot(vehiclesRef, (querySnapshot) => {
      const vehiclesData = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
        userId: userId, // Since we're querying under a specific user, you can set the userId directly
      }));
      setVehicles(vehiclesData);
    });
  
    // Cleanup the subscription on component unmount
    return () => unsubscribe();
  }, []);

  console.log(selectedCar);
  // console.log(selectedCar);
  return (
    <div className="w-screen ">
      {showReview ? (
        <div className="p-6  bg-white flex flex-col gap-6 rounded-lg shadow-lg  transition-transform transform-gpu duration-500 ease-in-out">
          {/* Selected Car Image and Details */}
          {selectedCar && (
            <div className="bg-white p-4 rounded-lg shadow-sm border border-gray-200 mt-4">
              <div className="lg:flex lg:gap-6">
                <div className="lg:w-1/3 flex-shrink-0 mb-4 lg:mb-0">
                  {selectedCar.images && selectedCar.images.length > 0 && (
                    <img
                      src={selectedCar.images[0]}
                      className="w-full rounded-lg shadow-md transition-transform transform hover:scale-105 duration-300"
                      alt="Selected Car"
                    />
                  )}
                  <div className="flex items-center mt-5 gap-48">
                      <button
                        className="bg-red-500 shadow-lg p-2 w-36 text-white rounded-full font-bold h-16"
                        onClick={handleGoBack}
                      >
                        Previous
                      </button>
                      <button
                        className="bg-green-500 shadow-lg p-2 w-36 text-white rounded-full font-bold h-16"
                        onClick={handleSave}
                      >
                        Pay ${TPrice.toLocaleString()}{" "}
                      </button>
                    </div>
                </div>
                <div className="lg:w-2/3 bg-white p-4 rounded-lg shadow-sm border border-gray-200">
                  <div className="grid grid-cols-1 gap-4">
                    <p className="text-gray-600 flex items-center">
                      <FaClock className="text-blue-500 mr-2" />
                      <strong className="text-gray-800">Duration:</strong>{" "}
                      {data.duration} {data.duration === 1 ? "hour" : "hours"}
                    </p>
                    <p className="text-gray-600 flex items-center">
                      <FaClock className="text-blue-500 mr-2" />
                      <strong className="text-gray-800">
                        Selected Duration:
                      </strong>{" "}
                      {data.time} hrs
                    </p>
                    <p className="text-gray-600 flex items-center">
                      <FaCalendarAlt className="text-purple-500 mr-2" />
                      <strong className="text-gray-800">Event:</strong>{" "}
                      {data.orderType}
                    </p>
                    <p className="text-gray-600 flex items-center">
                      <FaCalendarAlt className="text-purple-500 mr-2" />
                      <strong className="text-gray-800">By:</strong>{" "}
                      {data.date.toLocaleString()}
                    </p>
                    <p className="text-gray-600 flex items-center">
                      <FaUsers className="text-green-500 mr-2" />
                      <strong className="text-gray-800">With:</strong>{" "}
                      {data.passengerCount}{" "}
                      {data.passengerCount === 1 ? "Passenger" : "Passengers"}
                    </p>
                    <div className="text-gray-600 flex items-center">
                      <FaMapMarkerAlt className="text-yellow-500 mr-2" />
                      <strong>From:</strong> {data.pickUpLocation}
                    </div>
                    <div className="text-gray-600 flex items-center">
                      <FaMapMarkerAlt className="text-green-500 mr-2" />
                      <strong>To:</strong> {data.dropOffLocation}
                    </div>
                    <p className="text-gray-600 flex items-center">
                      <FaCar className="text-red-500 mr-2" />
                      <strong className="text-gray-800">In A:</strong>{" "}
                      {selectedCar.vehicleName}
                    </p>
                    <p className="text-gray-600 flex items-center">
                      <FaUsers className="text-green-500 mr-2" />
                      <strong className="text-gray-800">
                        Car Capacity:
                      </strong>{" "}
                      {selectedCar.passengers}
                    </p>

                    
                  </div>
                </div>
              </div>
            </div>
          )}
          <Review routeDetails={routeDetails} />
        </div>
      ) : (
        <div className="bg-white p-6 rounded-md shadow-lg">
          <div className="lg:flex gap-8 mb-5">
          <button
                onClick={() => {
                  setSavedData(null);
                  setShowSuccessScreen(false);
                }}
                className="bg-blue-600 p-3 px-20  rounded-md font-bold text-white"
              >
                Go Back
              </button>
            <div className="bg-gray-200 p-4 rounded-lg">
              <h2 className="font-bold text-lg text-gray-700">
                Selected Duration
              </h2>
              <span>
                {data.duration}, {data.duration === 1 ? "hour" : "hours"}
              </span>
            </div>
            <div className="bg-gray-200 p-4 rounded-lg">
              <h2 className="font-bold text-lg text-gray-700">Duration</h2>
              <span>{data.time ? data.time : data.duration} hours</span>
            </div>
            <div className="bg-gray-200 p-4 rounded-lg">
              <h2 className="font-bold text-lg text-gray-700">
                Pickup Date and Time
              </h2>
              <span>{data.date.toLocaleString()}</span>
            </div>
            <div className="bg-gray-200 p-4 rounded-lg">
              <h2 className="font-bold text-lg text-gray-700">Passenger</h2>
              <span>
                {data.passengerCount}{" "}
                {data.passengerCount === 1 ? "Passenger" : "Passengers"}
              </span>
            </div>
            <div className="bg-gray-200 p-4 rounded-lg">
              <h2 className="font-bold text-lg text-gray-700">
                Pick-up Location
              </h2>
              <span>{data.pickUpLocation}</span>
            </div>
            
          </div>

          <div>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 mt-10">
              {vehicles.map((vehicle, index) => {
                const totalCost =
                  parseInt(data.totalCost) + parseInt(vehicle.tripRatePerMile);
                return (
                  <div
                    key={vehicle.license}
                    className="bg-white rounded-md shadow-md p-4 flex flex-col"
                  >
                    <img
                      src={vehicle.images[0]}
                      alt={vehicle.vehicleName}
                      className="rounded-md w-full mb-4"
                    />
                    <div className="flex flex-col justify-between h-full">
                      <div className="text-gray-600 mb-4">
                        <h2 className="font-bold text-lg">
                          {vehicle.vehicleName}
                        </h2>
                        <p>
                          {vehicle.passengers}{" "}
                          {vehicle.passengers === 1
                            ? "Passanger"
                            : "Passangers"}
                        </p>
                        <p>{vehicle.vehicleType}</p>
                        <p className="mt-2">{vehicle.insurance}</p>
                      </div>
                      <div className="bg-gray-200 rounded-lg p-5 flex flex-col items-center">
                        <h2 className="font-bold text-lg pb-2">
                          Price ${totalCost.toLocaleString()}
                        </h2>
                        <button
                          className="bg-blue-600 text-white p-2 w-full rounded-full font-bold h-12"
                          onClick={() => handleCarSelection(vehicle, totalCost)}
                        >
                          Select
                        </button>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>

            <div className="flex justify-start mt-10">
              <button
                onClick={() => {
                  setSavedData(null);
                  setShowSuccessScreen(false);
                }}
                className="bg-blue-600 p-3 px-10 rounded-md font-bold text-white"
              >
                Go Back
              </button>
            </div>
          </div>
        </div>
      )}
      {showSuccess && <Success onClose={handleCloseSuccess} />}
    </div>
  );
}

export default CarTypes;
