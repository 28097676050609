import React, { useState } from 'react'
import { GoogleMap, Marker, LoadScript, Polyline } from '@react-google-maps/api';

const Foot = () => {
    const [directionsService, setDirectionsService] = useState(null);
    const [directionsRenderer, setDirectionsRenderer] = useState(null);
    const [userLocation, setUserLocation] = useState(null);
    const [route, setRoute] = useState(null);
    const [destination, setDestination] = useState(null);
    const [map, setMap] = useState(null);

    const handleLoad = (map) => {
        setMap(map);
    
        // Get the user's location using the Geolocation API
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            const userLatLng = { lat: latitude, lng: longitude };
            setUserLocation(userLatLng);
    
            // Center the map on the user's location and add a marker
            if (map) {
              map.setCenter(userLatLng);
              new window.google.maps.Marker({
                position: userLatLng,
                map: map,
                icon: {
                  url: 'https://firebasestorage.googleapis.com/v0/b/pcs-usen-49ac0.appspot.com/o/map%2F189333649-map-indicator-removebg-preview.png?alt=media&token=2c979ce2-fdcd-4b2d-9232-f824683834c3', // Replace with your user marker icon
                  scaledSize: new window.google.maps.Size(50, 50),
                },
              });
            }
          },
          (error) => {
            console.error('Error getting user location:', error);
          }
        );
    
        // Initialize Directions Service and Directions Renderer
        setDirectionsService(new window.google.maps.DirectionsService());
        setDirectionsRenderer(new window.google.maps.DirectionsRenderer());
      };

        const containerStyle = {
          width: '100%',
          height: '400px',
        };

  return (
    <div className='p-2 rounded-lg'>
         <LoadScript
      googleMapsApiKey="AIzaSyBo9CUsKlEGUkYTsjdTy5n6Q3X9i7ec-RQ"
      // googleMapsApiKey="AIzaSyCOP_91c5NHsGSYA5EXAe7TcX9FZsZ4azM"
      libraries={['places']}
      onLoad={handleLoad}
      className="shadow-lg"
    >
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={userLocation || { lat: 0, lng: 0 }}
        zoom={userLocation ? 12 : 5}
        mapTypeId="terrain"
      >
        {route && (
          <Polyline
            path={route}
            options={{
              strokeColor: '#FF0000',
              strokeOpacity: 1,
              strokeWeight: 4,
            }}
          />
        )}

        {userLocation && <Marker position={userLocation} icon={{ url: 'https://firebasestorage.googleapis.com/v0/b/pcs-usen-49ac0.appspot.com/o/map%2F189333649-map-indicator-removebg-preview.png?alt=media&token=2c979ce2-fdcd-4b2d-9232-f824683834c3', scaledSize: new window.google.maps.Size(50, 50), }} />}
        {destination && <Marker position={destination} icon={{ url: 'https://firebasestorage.googleapis.com/v0/b/pcs-usen-49ac0.appspot.com/o/map%2Fimages__2_-removebg-preview.png?alt=media&token=f9acf026-cd2d-43c2-8114-4605a24553ea', scaledSize: new window.google.maps.Size(50, 50), }} />}
      </GoogleMap>

      </LoadScript>
    </div>
  )
}

export default Foot