import React from 'react'
import { Link } from 'react-router-dom'

function GenFoot() {
  return (
    <div className='lg:p-32 p-8'>
            <h1 className='text-black lg:text-5xl text-3xl font-bold lg:ml-14 '>Our customers love us. Become one of them today!</h1>
        <div className='lg:flex justify-evenly'>
            <div className='mt-20'>
                <b className='text-green-500 text-7xl  font-bold'>20</b>
                <h2 className='text-black mt-4 font-bold text-2xl'>Years in the business</h2>
                <p className='mt-3'>We've been making our customers happy since <br /> 2001.</p>
            </div>
            <div className='mt-20'>
                <b className='text-green-500 text-7xl  font-bold'>95%</b>
                <h2 className='text-black mt-4 font-bold text-2xl'>Repeat Customers</h2>
                <p className='mt-3'>When people ride with us, they always come back for <br /> our excellent service and friendly drivers.</p>
            </div>
            <div className='mt-20'>
                <b className='text-green-500 text-7xl  font-bold'>10,000+</b>
                <h2 className='text-black mt-4 font-bold text-2xl'>Rides and Counting</h2>
                <p className='mt-3'>We've been everywhere and seen it all. Let us <br /> show you the difference that experience makes.</p>
            </div>
        </div>

         <div className='mt-28 bg-[url("https://lirp.cdn-website.com/fc25127f/dms3rep/multi/opt/Services+CTA-31501a02-2880w.jpg")] p-3 lg:p-10'>

            <div className='lg:h-[150%] md:w-[50%] lg:px-16 px-3 lg:py-20 py-9 rounded-xl bg-white'>

            {/* <div className=''> */}
                <h2 className='text-3xl font-bold mt-2'>
                Airport Shuttle or 
                </h2>
                <h2 className='md:text-3xl text-2xl font-bold mt-2'>City Tour? Make Your</h2>
                <h2 className='md:text-3xl text-2xl font-bold mt-2'>Online Reservation</h2>
                <p className='md:mt-10 mt-5 text-2xl'>
                We pride ourselves in delivering <br className='md:inline hidden' /> extensive services to fulfill all of your <br /> needs
                </p>
            {/* </div> */}

        <Link to={"/Booking"}>
            <button className='bg-green-500 hover:scale-110 duration-500 font-bold p-4 rounded-full text-white lg:mt-20 w-40 mt-10 shadow-md'>BOOK A RIDE</button>
        </Link>

        </div>
    </div>
    </div>
  )
}

export default GenFoot
