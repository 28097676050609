import React from "react";
import { useState } from "react";
import {
  GoogleMap,
  Marker,
  LoadScript,
  Polyline,
} from "@react-google-maps/api";
// import Select from 'react-select';
import { Options, CustomStyles, Type, CustomStyle } from "./data";
// import DateTimePicker from 'react-datetime-picker';
import { Types, ButtonToggle } from "./data";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";

// import RoundTrip from './RoundTrip';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { getFirestore, collection, addDoc } from "firebase/firestore";
import { firebase } from "../../firebaseConfig";
import { db } from "../../firebaseConfig";
import CarTypes from "./CarTypes";
// import { DatePicker, Select, Space, TimePicker } from 'antd';
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { Form, Button, Select, DatePicker, InputNumber, Row, Col } from "antd";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";

const containerStyle = {
  width: "100%",
  height: "100%",
};

const center = {
  lat: 40.713,
  lng: -74.006,
};

function RoundTrip() {
  const [map, setMap] = useState();
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedOption1, setSelectedOption1] = useState("one way journey");
  const [value, onChange] = useState(new Date());
  const [value1, onChange1] = useState(new Date());
  const [value2, onChange2] = useState(new Date());
  const [value3, onChange3] = useState(new Date());
  const [originInput, setOriginInput] = useState("");
  const [originInput1, setOriginInput1] = useState("");
  const [originInput2, setOriginInput2] = useState("");
  const [originInput3, setOriginInput3] = useState("");
  const [userLocation, setUserLocation] = useState(null);
  const [userLocation1, setUserLocation1] = useState(null);
  const [userLocation2, setUserLocation2] = useState(null);
  const [userLocation3, setUserLocation3] = useState(null);
  const [directionsService, setDirectionsService] = useState(null);
  const [directionsRenderer, setDirectionsRenderer] = useState(null);
  const [passengerCount, setPassengerCount] = useState(1);
  const [passengerCount1, setPassengerCount1] = useState(1);
  const [passengerCount2, setPassengerCount2] = useState(1);
  const [active, setActive] = useState("type1");
  const [active2, setActive2] = useState("type1");
  const [active1, setActive1] = useState("type1");
  const [selectedDuration, setSelectedDuration] = useState(null);
  const [showSuccessScreen, setShowSuccessScreen] = useState(false);
  const [savedData, setSavedData] = useState(null);
  const [routeDetails, setRouteDetails] = useState(null);

  const [originSuggestions, setOriginSuggestions] = useState([]);
  const [originSuggestions1, setOriginSuggestions1] = useState([]);
  const [originSuggestions2, setOriginSuggestions2] = useState([]);
  const [originSuggestions3, setOriginSuggestions3] = useState([]);

  const calculateDistanceAndCost = async () => {
    if (!userLocation || !userLocation1 || !directionsService) {
      console.error("User locations or Directions Service are not set");
      return;
    }

    try {
      const directionsRequest = {
        origin: userLocation,
        destination: userLocation1,
        travelMode: "DRIVING",
      };

      const directionsResult = await new Promise((resolve, reject) => {
        directionsService.route(directionsRequest, (result, status) => {
          if (status === "OK") {
            resolve(result);
          } else {
            reject(new Error(`Error calculating route: ${status}`));
          }
        });
      });

      const route = directionsResult.routes[0];
      const distanceInKm = route.legs[0].distance.value / 1000;
      const durationInHours = route.legs[0].duration.value / 3600;

      const baseCost = Math.round(distanceInKm * 150);
      const extraCostPerPerson = passengerCount > 1 ? (passengerCount - 1) * 50 : 0;
      const additionalTimeRequired = Math.max(0, distanceInKm / 100 - durationInHours);
      const additionalCost = Math.round(additionalTimeRequired * 150);
      const totalCost = baseCost + extraCostPerPerson + additionalCost;

      if (distanceInKm / durationInHours <= 100) {
        setSavedData({
          distance: distanceInKm,
          duration: durationInHours,
          passengerCount,
          pickUpLocation: originInput,
          dropOffLocation: originInput1,
          PickupVenue: active,
          DropOffVenue: active,
          additionalTimeRequired,
          orderType: selectedOption?.value || "none",
          totalCost,
          date: value,
        });

        setShowSuccessScreen(true);
      } else {
        const alertMessage = `Additional time required: ${additionalTimeRequired.toFixed(2)} hours\nAdditional cost: ${additionalCost}`;

        const userResponse = window.confirm(alertMessage);

        if (userResponse) {
          setSavedData({
            distance: distanceInKm,
            duration: durationInHours,
            passengerCount,
            pickUpLocation: originInput,
            dropOffLocation: originInput1,
            PickupVenue: active,
            DropOffVenue: active,
            additionalTimeRequired,
            orderType: selectedOption?.value || "none",
            totalCost,
            date: value,
          });
          setShowSuccessScreen(true);
        } else {
          console.log("User clicked Cancel. Aborting...");
        }
      }
    } catch (error) {
      console.error(error.message);
      alert(`Error calculating route: ${error.message}`);
    }
  };

  const handleLoad = (map) => {
    setMap(map);

    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        const userLatLng = { lat: latitude, lng: longitude };
        setUserLocation(userLatLng);

        if (map) {
          map.setCenter(userLatLng);
          new window.google.maps.Marker({
            position: userLatLng,
            map: map,
            icon: {
              scaledSize: new window.google.maps.Size(50, 50),
            },
          });
          console.log("yes");
        }
      },
      (error) => {
        console.error("Error getting user location:", error);
      }
    );

    setDirectionsService(new window.google.maps.DirectionsService());
    setDirectionsRenderer(new window.google.maps.DirectionsRenderer());
  };

  const handleSelect = async (address, stateUpdater, suggestionsUpdater) => {
    try {
      const results = await geocodeByAddress(address);
      const latLng = await getLatLng(results[0]);
      stateUpdater(latLng);
      suggestionsUpdater([]);
    } catch (error) {
      console.error("Error selecting location:", error);
    }
  };

  const handleOriginSelect = (address) => {
    setOriginInput(address);
    handleSelect(address, setUserLocation, setOriginSuggestions);
  };

  const handleOriginSelect1 = (address) => {
    setOriginInput1(address);
    handleSelect(address, setUserLocation1, setOriginSuggestions1);
  };

  const handleOriginSelect2 = (address) => {
    setOriginInput2(address);
    handleSelect(address, setUserLocation2, setOriginSuggestions2);
  };

  const handleOriginSelect3 = (address) => {
    setOriginInput3(address);
    handleSelect(address, setUserLocation3, setOriginSuggestions3);
  };


  return (
    <div className="min-h-screen p-6">
      <LoadScript
        googleMapsApiKey="AIzaSyBo9CUsKlEGUkYTsjdTy5n6Q3X9i7ec-RQ"
        libraries={["places"]}
      >
        <div
          className="reservation-container gap-3"
          style={{
            display: "flex",
            flexDirection: "row",
            // height: "100vh",
            padding: "24px",
            maxWidth: "1200px",
            margin: "0 auto",
            background: "#f9f9f9",
            borderRadius: "8px",
          }}
        >
          {/* Round Trip: Pick-Up */}
          <div
            style={{
              flex: 1,
              marginBottom: "16px",
              padding: "16px",
              backgroundColor: "#fff",
              borderRadius: "8px",
            }}
          >
            <h2>Round Trip: Pick-Up</h2>
            <Form layout="vertical" className="w-full">
              <Form.Item label="Date & Time" required>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    showTime
                    value={dayjs(value)}
                    onChange={(date) => onChange(date ? date.toDate() : null)}
                    format="YYYY-MM-DD HH:mm"
                  />
                </LocalizationProvider>
              </Form.Item>

              <Form.Item label="Pick-up Location" required>
                <div className="border-2 relative z-50 border-gray-300 hover:border-blue-300 h-12 text-start p-1 rounded-md">
                  <PlacesAutocomplete
                    value={originInput}
                    onChange={setOriginInput}
                    onSelect={handleOriginSelect}
                  >
                    {({
                      getInputProps,
                      suggestions,
                      getSuggestionItemProps,
                      loading,
                    }) => (
                      <div>
                        <input
                          className="h-10 w-full pl-4 outline-none"
                          {...getInputProps({
                            placeholder: "Type address",
                          })}
                        />
                        <div className="absolute top-full left z-20">
                          {suggestions.map((suggestion) => (
                            <div
                              className="bg-white shadow-lg z-50 p-2"
                              key={suggestion.id}
                              {...getSuggestionItemProps(suggestion)}
                            >
                              <p className="font-bold">
                                {suggestion.description}
                              </p>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </PlacesAutocomplete>
                </div>
              </Form.Item>

              <Form.Item label="Drop-off Location" required>
                <div className="border-2 relative border-gray-300 hover:border-blue-300 h-12 text-start p-1 rounded-md">
                  <PlacesAutocomplete
                    value={originInput1}
                    onChange={setOriginInput1}
                    onSelect={handleOriginSelect1}
                  >
                    {({
                      getInputProps,
                      suggestions,
                      getSuggestionItemProps,
                      loading,
                    }) => (
                      <div>
                        <input
                          className="h-10 w-full pl-4 outline-none"
                          {...getInputProps({
                            placeholder: "Type address",
                          })}
                        />
                        <div className="absolute top-full left z-20">
                          {loading && <div>Loading...</div>}
                          {suggestions.map((suggestion) => (
                            <div
                              className="bg-gray-50 shadow-lg p-2 z-30"
                              key={suggestion.id}
                              {...getSuggestionItemProps(suggestion)}
                            >
                              <p className="font-bold">
                                {suggestion.description}
                              </p>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </PlacesAutocomplete>
                </div>
              </Form.Item>

              <Form.Item label="Passenger Count" required>
                <InputNumber
                  min={1}
                  value={passengerCount}
                  onChange={setPassengerCount}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Form>
          </div>

          {/* Round Trip: Return */}
          <div
            style={{
              flex: 1,
              padding: "16px",
              backgroundColor: "#fff",
              borderRadius: "8px",
            }}
          >
            <h2>Round Trip: Return</h2>
            <Form layout="vertical" className="w-full">
              <Form.Item label="Date & Time" required>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    showTime
                    value={dayjs(value1)}
                    onChange={(date) => onChange1(date ? date.toDate() : null)}
                    format="YYYY-MM-DD HH:mm"
                  />
                </LocalizationProvider>
              </Form.Item>

              <div className="lg:text-left w-full text-center">
                <Form.Item
                  label="Pick-up Location"
                  name="pickupLocation"
                  required
                  rules={[
                    {
                      required: true,
                      message: "Please enter a pick-up location!",
                    },
                  ]}
                >
                  <div className="border-2 relative z-50 border-gray-300 hover:border-blue-300 h-12 text-start p-1 rounded-md">
                    <PlacesAutocomplete
                      value={originInput2}
                      onChange={setOriginInput2}
                      onSelect={handleOriginSelect2}
                      onLoad={handleLoad}
                    >
                      {({
                        getInputProps,
                        suggestions,
                        getSuggestionItemProps,
                        loading,
                      }) => (
                        <div>
                          <input
                            className="h-10 w-full pl-4 outline-none"
                            {...getInputProps({
                              placeholder: "Type address",
                            })}
                          />
                          <div className="absolute top-full left z-20">
                            {suggestions.map((suggestion) => (
                              <div
                                className="bg-white shadow-lg z-50 p-2"
                                key={suggestion.id}
                                {...getSuggestionItemProps(suggestion)}
                              >
                                <p className="font-bold">
                                  {suggestion.description}
                                </p>
                              </div>
                            ))}
                          </div>
                        </div>
                      )}
                    </PlacesAutocomplete>
                  </div>
                </Form.Item>
              </div>

              <div className="lg:text-left text-center w-full">
                <Form.Item
                  label="Drop-off Location"
                  name="dropoffLocation"
                  rules={[
                    {
                      required: true,
                      message: "Please enter a drop-off location!",
                    },
                  ]}
                >
                  <div className="border-2 relative border-gray-300 hover:border-blue-300 h-12 text-start p-1 rounded-md">
                    <PlacesAutocomplete
                      value={originInput3}
                      onChange={setOriginInput3}
                      onSelect={handleOriginSelect3}
                      onLoad={handleLoad}
                    >
                      {({
                        getInputProps,
                        suggestions,
                        getSuggestionItemProps,
                        loading,
                      }) => (
                        <div>
                          <input
                            className="h-10 w-full pl-4 outline-none"
                            {...getInputProps({
                              placeholder: "Type address",
                            })}
                          />
                          <div className="absolute top-full left z-20">
                            {loading && <div>Loading...</div>}
                            {suggestions.map((suggestion) => (
                              <div
                                className="bg-gray-50 shadow-lg p-2 z-30"
                                key={suggestion.id}
                                {...getSuggestionItemProps(suggestion)}
                              >
                                <p className="font-bold">
                                  {suggestion.description}
                                </p>
                              </div>
                            ))}
                          </div>
                        </div>
                      )}
                    </PlacesAutocomplete>
                  </div>
                </Form.Item>
              </div>

              <Form.Item label="Passenger Count" required>
                <InputNumber
                  min={1}
                  value={passengerCount1}
                  onChange={setPassengerCount1}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Form>
          </div>
        </div>
        <Row justify="center" className="gap-8 -mt-10">
          <Col>
            <Button>Cancel</Button>
          </Col>
          <Col>
            <Button
              type="primary"
              className="bg-blue-600"
              onClick={calculateDistanceAndCost}
            >
              Book
            </Button>
          </Col>
        </Row>
        {/* Google Map */}
      </LoadScript>
    </div>
  );
}

export default RoundTrip;
