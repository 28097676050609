import React, { useEffect, useState } from "react";
import {
  Form,
  Button,
  Select,
  DatePicker,
  InputNumber,
  Input,
  Menu,
  Row,
  Col,
} from "antd";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { getFirestore, collection, onSnapshot } from "firebase/firestore";
import { db } from "../../firebaseConfig";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { Types, Type } from "./data";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import CarTypes from "./CarTypes";

const containerStyle = {
  width: "100%",
  height: "100%",
};

const center = {
  lat: 40.713,
  lng: -74.006,
};

const Bet = ({ theme }) => {
  const [pickupLocation, setPickupLocation] = useState("");
  const [dropoffLocation, setDropoffLocation] = useState("");
  const [pickupCoords, setPickupCoords] = useState(center);
  const [dropoffCoords, setDropoffCoords] = useState(null);
  const [originInput, setOriginInput] = useState("");
  const [userLocation, setUserLocation] = useState(null);
  const [originSuggestions, setOriginSuggestions] = useState([]);
  const [originInput1, setOriginInput1] = useState("");
  const [value, onChange] = useState(new Date());
  const [userLocation1, setUserLocation1] = useState(null);
  const [originSuggestions1, setOriginSuggestions1] = useState([]);
  const [passengerCount, setPassengerCount] = useState(1);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedOption1, setSelectedOption1] = useState([]);
  const [map, setMap] = useState(null);
  const [directionsService, setDirectionsService] = useState(null);
  const [directionsRenderer, setDirectionsRenderer] = useState(null);
  const [active, setActive] = useState(Types[0]);
  const [active1, setActive1] = useState(Types[0]);
  const [selectedDuration, setSelectedDuration] = useState(null);
  const [showSuccessScreen, setShowSuccessScreen] = useState(false);
  const [savedData, setSavedData] = useState(null);
  const [routeDetails, setRouteDetails] = useState(null);
  const [Vehicles, setVehicles] = useState();

  const [options, setOptions] = useState([]);
  const [type, setType] = useState([]);

  const calculateDistanceAndCost = async () => {
    if (!userLocation || !userLocation1) {
      // User locations must be set before proceeding
      return;
    }

    const distanceMatrixService =
      new window.google.maps.DistanceMatrixService();
    const directionsService = new window.google.maps.DirectionsService();

    try {
      // Use Directions Service to calculate the route
      const directionsRequest = {
        origin: userLocation,
        destination: userLocation1,
        travelMode: "DRIVING",
      };

      const directionsResult = await new Promise((resolve, reject) => {
        directionsService.route(directionsRequest, (result, status) => {
          if (status === "OK") {
            resolve(result);
          } else {
            reject(new Error("No Official Road, Use Airlines"));
          }
        });
      });

      const route = directionsResult.routes[0];

      // Extract coordinates from overview_path
      const pathCoordinates = route.overview_path.map((path) => ({
        lat: path.lat(),
        lng: path.lng(),
      }));

      const routeDetails = {
        origin: {
          location: userLocation,
          address: originInput,
        },
        destination: {
          location: userLocation1,
          address: originInput1,
        },
        route,
      };

      setRouteDetails(routeDetails);

      const response = await new Promise((resolve, reject) => {
        const distanceMatrixRequest = {
          origins: [userLocation],
          destinations: [userLocation1],
          travelMode: "DRIVING",
        };

        distanceMatrixService.getDistanceMatrix(
          distanceMatrixRequest,
          (response, status) => {
            if (status === "OK") {
              resolve(response);
            } else {
              reject(new Error(`Error calculating distance: ${status}`));
            }
          }
        );
      });

      const distanceInKm = response.rows[0].elements[0].distance.value / 1000;
      const durationInHours = parseFloat(selectedOption1?.value || 0);
      const orderType = selectedOption?.value || "none";
      const baseCost = Math.round(distanceInKm * 150);
      const extraCostPerPerson = passengerCount > 1 ? passengerCount * 50 : 0;
      const additionalTimeRequired = distanceInKm / 100 - durationInHours;
      const additionalCost = Math.round(additionalTimeRequired * 150);
      const totalCost = baseCost + extraCostPerPerson;

      if (distanceInKm / durationInHours <= 100) {
        // Save the data to Firebase
        console.log("Data saved to Firebase:", {
          distance: distanceInKm,
          duration: durationInHours,
          passengerCount,
          totalCost,
          pickUpLocation: originInput,
          dropOffLocation: originInput1,
          PickupVenue: active,
          DropOffVenue: active,
          orderType: orderType,
          time: additionalTimeRequired,
          date: value.toString(),
        });

        setSavedData({
          distance: distanceInKm,
          duration: durationInHours,
          passengerCount,
          pickUpLocation: originInput,
          dropOffLocation: originInput1,
          PickupVenue: active,
          DropOffVenue: active,
          time: additionalTimeRequired,
          orderType: orderType,
          totalCost: totalCost,
          date: value,
        });

        setShowSuccessScreen(true);
      } else {
        const alertMessage = `Additional time required: ${additionalTimeRequired} hours\nAdditional cost: ${additionalCost}`;

        const userResponse = window.confirm(alertMessage);

        if (userResponse) {
          // Proceed to the next step
          console.log("User clicked OK. Proceeding to the next step...");
          setSavedData({
            distance: distanceInKm,
            duration: durationInHours,
            passengerCount,
            pickUpLocation: originInput,
            dropOffLocation: originInput1,
            PickupVenue: active,
            DropOffVenue: active,
            time: additionalTimeRequired,
            orderType: orderType,
            totalCost: totalCost,
            date: value,
          });
          setShowSuccessScreen(true);
        } else {
          console.log("User clicked Cancel. Aborting...");
          // Handle user's cancellation if necessary
        }
      }
    } catch (error) {
      console.error(error.message);
      alert(
        `Cars don't drive from ` +
          originInput +
          " to " +
          originInput1 +
          ": " +
          error.message
      );
      // Handle the error appropriately
    }
  };

  const handleLoad = (map) => {
    setMap(map);

    // Get the user's location using the Geolocation API
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        const userLatLng = { lat: latitude, lng: longitude };
        setUserLocation(userLatLng);

        // Center the map on the user's location and add a marker
        if (map) {
          map.setCenter(userLatLng);
          new window.google.maps.Marker({
            position: userLatLng,
            map: map,
            icon: {
              scaledSize: new window.google.maps.Size(50, 50),
            },
          });
          console.log("User location marker added");
        }
      },
      (error) => {
        console.error("Error getting user location:", error);
      }
    );

    // Initialize Directions Service and Directions Renderer
    setDirectionsService(new window.google.maps.DirectionsService());
    setDirectionsRenderer(new window.google.maps.DirectionsRenderer());
  };

  const handleSelect = async (address, stateUpdater, suggestionsUpdater) => {
    try {
      const results = await geocodeByAddress(address);
      const latLng = await getLatLng(results[0]);
      stateUpdater(latLng);
      suggestionsUpdater([]);
      //   console.log(Options)
    } catch (error) {
      console.error("Error selecting location:", error);
    }
  };
  const handleOriginSelect = (address) => {
    setOriginInput(address);
    handleSelect(address, setUserLocation, setOriginSuggestions);
  };
  const handleSelect1 = async (address, stateUpdater, suggestionsUpdater) => {
    try {
      const results = await geocodeByAddress(address);
      const latLng = await getLatLng(results[0]);
      stateUpdater(latLng);
      suggestionsUpdater([]);
    } catch (error) {
      console.error("Error selecting location:", error);
    }
  };
  const handleOriginSelect1 = (address) => {
    setOriginInput1(address);
    handleSelect1(address, setUserLocation1, setOriginSuggestions1);
  };

  useEffect(() => {
    const unsubscribe = onSnapshot(
      collection(db, "vehicles"),
      (querySnapshot) => {
        const vehiclesData = querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        setVehicles(vehiclesData);
        // setLoading(false);
      }
    );
    return () => unsubscribe();
  }, []);

  const Options = [
    { value: "0.5", label: "0.5Hour" },
    { value: "1", label: "1Hour" },
    { value: "1.5", label: "1.5Hours" },
    { value: "2", label: "2Hours" },
    { value: "2.5", label: "2.5Hours" },
    { value: "3", label: "3Hours" },
    { value: "3.5", label: "3.5Hours" },
    { value: "4", label: "4Hours" },
    { value: "4.5", label: "4.5Hours" },
    { value: "5", label: "5Hours" },
    { value: "5.5", label: "5.5Hours" },
    { value: "6", label: "6Hours" },
    { value: "6.5", label: "6.5Hours" },
    { value: "7", label: "7Hours" },
    { value: "7.5", label: "7.5Hours" },
    { value: "8", label: "8Hours" },
  ];

  return (
    <div className="min-h-screen p-6">
      {savedData && (
        <CarTypes
          routeDetails={routeDetails}
          setShowSuccessScreen={setShowSuccessScreen}
          data={savedData}
          setSavedData={setSavedData}
        />
      )}
      <LoadScript
        googleMapsApiKey="AIzaSyBo9CUsKlEGUkYTsjdTy5n6Q3X9i7ec-RQ"
        libraries={["places"]}
        onLoad={handleLoad}
      >
        {!savedData && (
          <div
            className="reservation-container"
            style={{
              display: "flex",
              height: "100vh",
              padding: "24px",
              maxWidth: "1200px",
              margin: "0 auto",
              background: "#f9f9f9",
              borderRadius: "8px",
            }}
          >
            {/* Form Section */}
            <div
              style={{
                flex: 1,
                marginRight: "16px",
                overflowY: "auto",
                padding: "16px",
                backgroundColor: "#fff",
                borderRadius: "8px",
              }}
            >
              <div className={` ${showSuccessScreen ? "hidden" : ""}`}>
                <Form layout="vertical" className="w-full">
                  <Form.Item className="" label="Duration" required>
                    <Select
                      placeholder="Duration"
                      label="Duration"
                      // defaultValue={selectedOption1}
                      onChange={setSelectedOption1}
                      options={Options}
                      value={selectedOption1}
                      className="w-full"
                    />
                  </Form.Item>

                  <div className="text-left">
                    <Form.Item label="Order Type" required>
                      <Select
                        className="w-full"
                        placeholder="Order Type"
                        value={selectedOption}
                        onChange={setSelectedOption}
                        options={Type}
                      />
                    </Form.Item>
                  </div>

                  <div className="text-left">
                    <Form.Item label="Date & Time" required>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          showTime
                          value={dayjs(value)}
                          onChange={(date) =>
                            onChange(date ? date.toDate() : null)
                          }
                          format="YYYY-MM-DD HH:mm"
                        />
                      </LocalizationProvider>
                    </Form.Item>
                  </div>

                  <div className="lg:text-left w-full text-center">
                    <Form.Item
                      label="Pick-up Location"
                      name="pickupLocation"
                      required
                      rules={[
                        {
                          required: true,
                          message: "Please enter a pick-up location!",
                        },
                      ]}
                    >
                      <div className="border-2 relative z-50 border-gray-300 hover:border-blue-300 h-12 text-start p-1 rounded-md">
                        <PlacesAutocomplete
                          value={originInput}
                          onChange={setOriginInput}
                          onSelect={handleOriginSelect}
                          onLoad={handleLoad}
                        >
                          {({
                            getInputProps,
                            suggestions,
                            getSuggestionItemProps,
                            loading,
                          }) => (
                            <div>
                              <input
                                className="h-10 w-full pl-4 outline-none"
                                {...getInputProps({
                                  placeholder: "Type address",
                                })}
                              />
                              <div className="absolute top-full left z-20">
                                {suggestions.map((suggestion) => (
                                  <div
                                    className="bg-white shadow-lg z-50 p-2"
                                    key={suggestion.id}
                                    {...getSuggestionItemProps(suggestion)}
                                  >
                                    <p className="font-bold">
                                      {suggestion.description}
                                    </p>
                                  </div>
                                ))}
                              </div>
                            </div>
                          )}
                        </PlacesAutocomplete>
                      </div>
                    </Form.Item>
                  </div>

                  <div className="lg:text-left text-center w-full">
                    <Form.Item
                      label="Drop-off Location"
                      name="dropoffLocation"
                      rules={[
                        {
                          required: true,
                          message: "Please enter a drop-off location!",
                        },
                      ]}
                    >
                      <div className="border-2 relative border-gray-300 hover:border-blue-300 h-12 text-start p-1 rounded-md">
                        <PlacesAutocomplete
                          value={originInput1}
                          onChange={setOriginInput1}
                          onSelect={handleOriginSelect1}
                          onLoad={handleLoad}
                        >
                          {({
                            getInputProps,
                            suggestions,
                            getSuggestionItemProps,
                            loading,
                          }) => (
                            <div>
                              <input
                                className="h-10 w-full pl-4 outline-none"
                                {...getInputProps({
                                  placeholder: "Type address",
                                })}
                              />
                              <div className="absolute top-full left z-20">
                                {loading && <div>Loading...</div>}
                                {suggestions.map((suggestion) => (
                                  <div
                                    className="bg-gray-50 shadow-lg p-2 z-30"
                                    key={suggestion.id}
                                    {...getSuggestionItemProps(suggestion)}
                                  >
                                    <p className="font-bold">
                                      {suggestion.description}
                                    </p>
                                  </div>
                                ))}
                              </div>
                            </div>
                          )}
                        </PlacesAutocomplete>
                      </div>
                    </Form.Item>
                  </div>

                  <Form.Item label="Passengers:" name="travellers" required>
                    <InputNumber
                      min={1}
                      defaultValue={2}
                      style={{ width: "100%" }}
                      value={passengerCount}
                      onChange={(value) => setPassengerCount(value)}
                    />
                  </Form.Item>

                  <Row justify="space-between">
                    <Col>
                      <Button>Cancel</Button>
                    </Col>
                    <Col>
                      <Button
                        type="primary"
                        className="bg-blue-600"
                        onClick={calculateDistanceAndCost}
                      >
                        Book
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </div>
            </div>

            {/* Map Section */}
            {/* <div className="hidden lg:block flex-1 h-full p-4 bg-white rounded-lg">
              <LoadScript
                googleMapsApiKey="AIzaSyBo9CUsKlEGUkYTsjdTy5n6Q3X9i7ec-RQ"
                libraries={["places"]}
                onLoad={handleLoad}
              >
                <GoogleMap
                  mapContainerStyle={containerStyle}
                  center={center}
                  zoom={15}
                >
                  <Marker position={center} />
                </GoogleMap>
              </LoadScript>
            </div> */}
          </div>
        )}
      </LoadScript>
    </div>
  );
};

export default Bet;
