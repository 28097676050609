import React from 'react'
import GenFoot from './GenFoot'
import Review from './Review'

const Vehicles = () => {
  return (
    <div>
        <div className='bg-[url("https://lirp.cdn-website.com/fc25127f/dms3rep/multi/opt/Services+Hero-2880w.jpg")] lg:h-screen items-center pt-32 md:pt-10 lg:pt-2 pb-10 bg-center bg-no-repeat bg-cover lg:p-24'>
        <div className='items-center  lg:mt-[16%] text-center'>
            <h2 className='text-gray-400 mt-1 lg:mt-8 font-bold pl-5 lg:pl-0'>OUR FLEET</h2>
            <h1 className='text-gray-100 lg:text-7xl text-4xl pl-5 lg:pl-0 font-bold mt-5'>Luxurious, clean and <br className='lg:inline hidden' /> comfortable</h1>
            <h2 className='text-gray-300 text-center self-center px-2 mt-14 text-xl lg:pl-0'>We have vehicles to accommodate for large and small groups for every occasion. You’ll enjoy your ride with modern amenities and arrive in style in one of our          luxurious vehicles. Our incredible fleet provides our clients with many professional vehicle options.

                Black Town cars, Luxury SUVs, Luxury Sedans, Stretch Limos, Stretch SUV Limos, Luxury Sprinter Van Limos & Shuttles,    Party buses, Mini Buses, and Charter Buses.

            No matter your group’s size, event type, or outing location, we have the perfect vehicle and services for you. Your comfort and safety are our top priority.</h2>
         </div>
        </div>
        {/* <Review routeDetails={routeDetails} /> */}
        <GenFoot  />
    </div>
  )
}

export default Vehicles