import React, { useEffect, useState } from 'react'
import {HiOutlineMenuAlt3} from 'react-icons/hi'
import {HiOutlineMenuAlt2} from 'react-icons/hi'
import { Link } from 'react-router-dom';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { auth, firestore } from '../firebaseConfig'
import './load.css'
import { BiSolidHide } from "react-icons/bi";
import Switch from 'react-switch';


const user = auth.currentUser;
const userRef = firestore.collection('users').doc('someUserId');
const Nav = () => {
    const [nav, setNav] = useState(false);
    const [user, setUser] = useState(null);
    const [type, setType] = useState(false);
    const [loading, setLoading] = useState(false);
    const [profile, setProfile] =useState(false)
    const [isActive, setIsActive] = useState(false);
    const [isAgent, setIsAgent] = useState(null);
    const [agents, setAgents] = useState([]);
    const [location, setLocation] = useState('');
    const [destination, setDestination] = useState('');
    const [leavingTime, setLeavingTime] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('')
    const [checked, setChecked] = useState( () => {
      const storedState = localStorage.getItem('isActive');
      return storedState ? JSON.parse(storedState) : false;
    });
    const handleNave = () =>{
        setNav(!nav)
    }
    const handleType = () => {
        setType(!type)
    }
    const handleProfile = () => {
        setProfile(!profile)
    }


    useEffect(() => {
      // Check if the user is already signed in
      const unsubscribe = firebase.auth().onAuthStateChanged((authUser) => {
        if (authUser) {
          setUser(authUser);
          const userRef = firebase.firestore().collection('users').doc(authUser.uid);
          userRef.get().then((doc) => {
            if (doc.exists) {
              const userIsAgent = doc.data().isAgent;
              // Ensure isAgent is a boolean value
              if (typeof userIsAgent === 'boolean') {
                setIsAgent(userIsAgent);
              } else {
                console.error("Invalid isAgent value in Firestore:", userIsAgent);
                setIsAgent(null);
              }
              const userIsActive = doc.data().isActive;
               setIsActive(userIsActive !== undefined ? userIsActive : false);
            } else {
              // Handle the case where the user document doesn't exist
              console.error("User document not found in Firestore");
              setIsAgent(null);
            }
          }).catch((error) => {
            console.error("Error retrieving user document:", error);
            setIsAgent(null);
          });
        } else {
          setUser(null);
          setIsAgent(null);
        }
      });
  
      return () => unsubscribe();
    }, []);

    // const handleToggle = () => {
    //     // Update the isActive field in Firestore on every toggle
    //     const userRef = firebase.firestore().collection('users').doc(user.uid);
    //     userRef.update({
    //       isActive: !isActive,
    //     }).then(() => {
    //       setIsActive(!isActive);
    //     }).catch((error) => {
    //       console.error("Error updating isActive field:", error);
    //     });
    //   };
  
const handleInChange = (e) => {
  setPhoneNumber(e.target.value);
};
const handlePhone = async () => {
  const user = firebase.auth().currentUser;
  const userRef = firebase.firestore().collection('users').doc(user.uid);

  // Check if the document exists
  const doc = await userRef.get();

  if (doc.exists) {
    // Document exists, get the existing phone number
    const userData = doc.data();
    const existingPhoneNumber = userData.phoneNumber || '';

    // Update the document with the new phone number
    await userRef.update({
      phoneNumber: phoneNumber,
    });

    // Remove the old phone number if it exists
    if (existingPhoneNumber) {
      await userRef.update({
        phoneNumber: firebase.firestore.FieldValue.delete(),
      });
    }
  } else {
    // Document doesn't exist, create it with the phone number field
    await userRef.set({
      phoneNumber: phoneNumber,
    });
  }
};


useEffect(() => {
  // Save the current state to local storage whenever it changes
  localStorage.setItem('isActive', JSON.stringify(checked));
}, [checked]);
const handleToggle = async (val) => {
  if (val) {
    // Prompt the user for location, destination, and leaving time
    const userLocation = prompt('Enter your current location:');
    const userDestination = prompt('Enter your destination:');
    const userLeavingTime = prompt('Enter when you are leaving:');

    // Update the user's profile in Firestore
    const user = firebase.auth().currentUser;
    const userRef = firebase.firestore().collection('users').doc(user.uid);

    await userRef.update({
      isActive: val,
      location: userLocation,
      destination: userDestination,
      leavingTime: userLeavingTime,
    });

    // Update the state in the component
    setLocation(userLocation);
    setDestination(userDestination);
    setLeavingTime(userLeavingTime);
    setPhoneNumber(phoneNumber);
  } else {
    // Deactivate: Remove location, destination, and leaving time from the user's profile
    const user = firebase.auth().currentUser;
    const userRef = firebase.firestore().collection('users').doc(user.uid);

    await userRef.update({
      isActive: false,
      location: '',
      destination: '',
      leavingTime: '',
    });

    // Update the state in the component
    setLocation('');
    setDestination('');
    setLeavingTime('');
  }

  setChecked(val);
};


    const handleSignIn = async (isAgent) => {
      const provider = new firebase.auth.GoogleAuthProvider();
  
      // Set custom parameters for the sign-in process
      provider.setCustomParameters({
        prompt: 'select_account',
      });
  
      try {
        // Sign in with the popup
        setLoading()
        const result = await firebase.auth().signInWithPopup(provider);
  
        // Check if the user is new and prompt for registration type
        if (result.additionalUserInfo.isNewUser) {
          // Update user profile with registration type
          await result.user.updateProfile({
            displayName: result.user.displayName,
            photoURL: result.user.photoURL,
            isAgent: isAgent,
          });
  
          // Store user details in Firestore
          await firebase.firestore().collection('users').doc(result.user.uid).set({
            displayName: result.user.displayName,
            email: result.user.email,
            photoURL: result.user.photoURL,
            isAgent: isAgent,
          });
        }
      } catch (error) {
        console.error('Error signing in:', error.message);
      }
    };

  
    const handleSignOut = async () => {
      try {
        await firebase.auth().signOut();
        setUser(null);
      } catch (error) {
        console.error('Error signing out:', error.message);
      }
    };
    const fetchAgents = async () => {
      setLoading(true);
  
      try {
        // Query users collection where isAgent is true
        const querySnapshot = await firebase.firestore().collection('users').where('isAgent', '==', true).get();
  
        // Map the documents to an array of user objects
        const agentList = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
  
        setAgents(agentList);
      } catch (error) {
        console.error('Error fetching agents:', error);
      } finally {
        setLoading(false);
      }
    };
  
    useEffect(() => {
      fetchAgents();
    }, []);


  return (
    <div className='p-2 lg:pt-4 pt-5 shadow-md z-50 fixed w-screen items-center bg-gray-100 h-20'>
        <div className='md:hidden'>
          <div className='right-5 z-50 absolute'>
            {
                !nav ?
                (

                    <HiOutlineMenuAlt3 size={30} onClick={handleNave}/>
                ) :
                (

                    <HiOutlineMenuAlt2 size={30}  onClick={handleNave}/>
                )
            }
          </div>
            {nav ? (
            <div className=" bg-gray-100 z-40 h-64 duration-100 w-[100%] p-1 absolute  ease-in-out">
            <ul className=' items-start flex flex-col  mr-10 gap-6 text-start p-1 ml- '>
            <Link to={'/'} className='text-lg bg-white rounded-sm w-full p-1 font-bold text-[#0066ff]'>Home</Link>
            <Link to={'Agents'} className='text-lg bg-white rounded-sm w-full p-1 font-bold text-[#0066ff]'>Agents</Link>
            {isAgent  === true ? ( <Link to={'Works'} className='text-lg p-1 w-full bg-white rounded-sm font-bold text-[#0066ff]'>Activities</Link> ) : null }
            <Link to={'profile'} className='text-lg font-bold bg-white rounded-sm w-full p-1 text-[#0066ff]'>my orders</Link>
            
            {user ? (
                    <div className='z-50 -mt-32'>
                        <img onClick={handleProfile} className='rounded-full absolute right-20 top-5' src={user.photoURL} alt="Profile" style={{ width: '50px', height: '50px', border: `2px solid ${isAgent === true ? 'red' : 'green'}`,  }} />
                        <div>
                            {profile ? (
                              <div className='absolute z-20 bg-white top-5 h-96 shadow-xl p-3 right-10'>
                                  <BiSolidHide size={30} color='red' onClick={handleProfile} className='absolute top-0'/>
                                  {/* <BiSolidHide /> */}
                                    <p className='font-bold text-2xl m-5'>Welcome, {user.displayName}!</p>
                                    <p>{user.email}</p>
                                    {/* <p>Profile Image: <img src={user.photoURL} alt="Profile" style={{ width: '50px', height: '50px' }} /></p> */}
                                    {isAgent !== null ? (isAgent ? 'Agent' : 'Client') : 'Loading...'} <br />
                                    {isAgent ===true ?  (
                                            <div>
                                                {/* <p>Contracts</p> */}
                                                <p className='my-2'>Status: <b className=''> {isActive ? 'Active' : 'Inactive'} </b> </p>
                                                  {/* <button onClick={handleToggle}>{isActive ? 'Deactivate' : 'Activate'}</button> */}
                                                  <Switch checked={checked} onChange={handleToggle} />
                                                  <div className="flex items-center justify-center  space-x-4">
                                          </div>
                                          <div className='border-b-2  mb-2 mt-5'>
                                            {/* <label > Phone Number</label> */}
                                            {/* { agents.map ((agent) => */}
                                              <div>
                                                {/* { phoneNumber ? */}
                                                <p>{phoneNumber}</p> 
                                                <input className='border-2 border-green-200 rounded-md h-9 p-2 w-60' type="number" placeholder={'enter Phone number'} name="" id="" onChange={handleInChange} /> 
                                                {/* } */}
                                                </div>
                                              {/* )
                                            } */}
                                            <div>                            
                                            </div>
                                            <button className='bg-[#0066ff] font-bold rounded-md p-2 text-white  my-3' onClick={handlePhone}>submit</button>
                                          </div>
                                          
                                        </div>
                                    ) : null}
                                    <button className='bg-red-400 w-full h-10 text-white font-bold shadow-lg' onClick={handleSignOut}>Sign Out</button>
                                </div>
                            ):null}
                        </div>
                    </div>
                ):(
            <div>
            {type ? (
                <div className='flex flex-col absolute gap-10 z-20 top-1 right-5 shadow-lg bg-white p-5'>
                            <h3 onClick={handleType} className='font-bold text-[#0066ff]'>Sign In as</h3>
                <li onClick={() => handleSignIn(true)} className='text-xl font-bold text-blue-500 border-[0.8px] p-2 border-blue-500 w-28 h-12 rounded-md'>Agent</li>

                <li onClick={() => handleSignIn(false)} className='text-xl  font-bold text-green-500 border-[0.8px] p-2 border-green-500 w-28 h-12 rounded-md'> Client</li>
                    </div>
                ):null}
                                
                    <li onClick={handleType} className='text-xl font-bold bg-[#0066ff] h-12 p-2 w-28 rounded-md text-white'>Sign In</li>
                
            </div>
                )}
            
           </ul>
            </div>
            ):(<></>)}
        </div>
        <div className='flex justify-between items-center '>
            <Link to={"/"} className='text-2xl ml-5 font-bold items-center text-[#0066ff]'>
                PCS-USEN
            </Link>
        <ul className='md:flex hidden items-center mr-10 gap-14 text-center '>
            <Link to={'/'} className='text-lg font-bold text-[#0066ff]'>Home</Link>
            <Link to={'Booking'} className='text-lg font-bold text-[#0066ff]'>Book</Link>
            <Link to={'Agents'} className='text-lg font-bold text-[#0066ff]'>Agents</Link>
            {isAgent  === true ? ( <Link to={'Works'} className='text-lg font-bold text-[#0066ff]'>Activities</Link> ) : null }
            <Link to={'profile'} className='text-lg font-bold text-[#0066ff]'>my orders</Link>

                {user ? (
                    <div className=''>
                        <img onClick={handleProfile} className='rounded-full' src={user.photoURL} alt="Profile" style={{ width: '50px', height: '50px', border: `2px solid ${isAgent === true ? 'red' : 'green'}`,  }} />
                        <div>
                            {profile ? (
                                <div className='absolute z-20 bg-white top-20 h-96 shadow-xl p-3 right-10'>
                                    <p className='font-bold text-2xl m-5'>Welcome, {user.displayName}!</p>
                                    <p>{user.email}</p>
                                    {/* <p>Profile Image: <img src={user.photoURL} alt="Profile" style={{ width: '50px', height: '50px' }} /></p> */}
                                    {isAgent !== null ? (isAgent ? 'Agent' : 'Client') : 'Loading...'} <br />
                                    {isAgent ===true ?  (
                                            <div>
                                                {/* <p>Contracts</p> */}
                                                <p className='my-2'>Status: <b className=''> {isActive ? 'Active' : 'Inactive'} </b> </p>
                                                  {/* <button onClick={handleToggle}>{isActive ? 'Deactivate' : 'Activate'}</button> */}
                                                  <Switch checked={checked} onChange={handleToggle} />
                                                  <div className="flex items-center justify-center  space-x-4">
                                          </div>
                                          <div className='border-b-2  mb-2 mt-5'>
                                            {/* <label > Phone Number</label> */}
                                            {/* { agents.map ((agent) => */}
                                              <div>
                                                {/* { phoneNumber ? */}
                                                <p>{phoneNumber}</p> 
                                                <input className='border-2 border-green-200 rounded-md h-9 p-2 w-60' type="number" placeholder={'enter Phone number'} name="" id="" onChange={handleInChange} /> 
                                                {/* } */}
                                                </div>
                                              {/* )
                                            } */}
                                            <div>                            
                                            </div>
                                            <button className='bg-[#0066ff] font-bold rounded-md p-2 text-white  my-3' onClick={handlePhone}>submit</button>
                                          </div>
                                                    {/* {isActive && (
                                            <div>
                                              <p>Location: {location}</p>
                                              <p>Destination: {destination}</p>
                                              <p>Leaving Time: {leavingTime}</p>
                                            </div>
                                          )} */}
                                        </div>
                                    ) : null}
                                    <button className='bg-red-400 w-full h-10 text-white font-bold shadow-lg' onClick={handleSignOut}>Sign Out</button>
                                </div>
                            ):null}
                        </div>
                    </div>
                ):(
            <div>
            {type ? (
                <div className='flex flex-col absolute gap-10 z-20 top-1 right-5 shadow-lg bg-white p-5'>
                            <h3 onClick={handleType} className='font-bold text-[#0066ff]'>Sign In as</h3>
                <li onClick={() => handleSignIn(true)} className='text-xl font-bold text-blue-500 border-[0.8px] p-2 border-blue-500 w-28 h-12 rounded-md'>Agent</li>

                <li onClick={() => handleSignIn(false)} className='text-xl  font-bold text-green-500 border-[0.8px] p-2 border-green-500 w-28 h-12 rounded-md'> Client</li>
                    </div>
                ):null}
                                
                    <li onClick={handleType} className='text-xl font-bold bg-[#0066ff] h-12 p-2 w-28 rounded-md text-white'>Sign In</li>
                
            </div>
                )}
        </ul>
        </div>
        {loading && <p>Loading...</p>}

    </div>
  )
}

export default Nav