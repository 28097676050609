import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyCT4VYyzHbWO0zgrjOgIixAvfqeX1wWZYY",
  authDomain: "limoh-e27e5.firebaseapp.com",
  projectId: "limoh-e27e5",
  storageBucket: "limoh-e27e5.appspot.com",
  messagingSenderId: "289133088100",
  appId: "1:289133088100:web:f04c4e18b16717bd493e71",
  measurementId: "G-EMR5VNJSF2"
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

const auth = firebase.auth(); // Use the auth module
const firestore = firebase.firestore();
const db = firebase.firestore();

export { auth, firestore, firebase, db }; // Export the modules
