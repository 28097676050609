import React, { useEffect, useState } from "react";
import { Col, Menu, Row } from "antd";
import OneWay from "./tripsccreens/OneWay";
import RoundTrip from "./tripsccreens/RoundTrip";
import Bet from "./tripsccreens/Bet";
import vid from '../Assets/55.mp4'


const containerStyle = {
  width: "100%",
  height: "100%",
};

const center = {
  lat: 40.713,
  lng: -74.006,
};

const Booking = ({ theme }) => {
  const [currentScreen, setCurrentScreen] = useState("hourly");

  const menuItems = [
    { key: "hourly", label: "Hourly " },
    { key: "round_trip", label: "One Way " },
    { key: "one_way", label: "Round Trip" },
  ];

  const handleMenuClick = (e) => {
    setCurrentScreen(e.key);
  };

  return (
    <Row>
         <div
  // style={{width: '100%'}}
  className="w-[100%]  h-[100%] flex justify-center items-center object-cover absolute mt-12"
  dangerouslySetInnerHTML={{
    __html: `<video  style="
    width: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    height: 100%;
    object-fit: cover;
    // z-index: -1;" 
    autoplay loop muted playsinline>
    <source src=${vid} type="video/mp4" />
    Your browser does not support the video tag.
  </video>`
}}
/> 
      <Col span={24}>
        <Menu
          onClick={handleMenuClick}
          selectedKeys={[currentScreen]}
          mode="horizontal"
          items={menuItems}
          className="lg:ml-40"
        />
      </Col>
      <Col span={24}>
        {currentScreen === "hourly" && (
          <div>
            <Bet />
          </div>
        )}
        {currentScreen === "one_way" && (
          <div>
            {/* Add the form or content for Location Bookings here */}
            <RoundTrip />

          </div>
        )}
        {currentScreen === "round_trip" && (
          <div>
            {/* Add the form or content for Location Bookings here */}
            <OneWay />

          </div>
        )}
      </Col>
    </Row>
  );
};

export default Booking;
